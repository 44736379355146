<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">用户消费汇总</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="10">
            <!-- <el-col :span="2"></el-col> -->
            <el-col :span="7">
              <el-form-item label="车型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择车型"
                  clearable
                  style="width:100%"
                >
                <el-option :value="2" label="电动车"></el-option>
                <el-option :value="4" label="电动汽车"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col v-show="isadmin" :span="6">
                <el-form-item label="运营商:">
                  <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%;">
                    <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username"/>
                  </el-select>
                </el-form-item>
              </el-col> -->
            <el-col :span="7">
              <el-form-item label="消费时间:" class="time_pick">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  unlink-panels
                  range-separator=" - "
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div
                class="BtnSearch"
                style="margin-top: 5px"
                @click="searchBtn()"
              >
                查询
              </div>
            </el-col>
            <el-col :span="1" style="margin-left:40px; margin-top: 5px">
              <Data2Excel api='/report/user_consumption_statistics' :data="searchParam" fileName="用户消费汇总"></Data2Excel>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="540px"
          style="width: 90%; margin: 20px auto;overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" width="250" />
          <!-- <el-table-column
            label="电动车消费金额(元)"
            prop="sum_cost_money"
            :formatter="rounding"
          />
          <el-table-column label="电动车充电次数" prop="frequency" />
          <el-table-column
            label="电动车充电时长(分钟)"
            prop="sum_cost_time"
            :formatter="rounding"
          />
          <el-table-column label="电动车充电量(度)" prop="sum_quantity" /> -->
          <el-table-column
            label="消费金额(元)"
            prop="sum_cost_money"
            :formatter="rounding"
          />
          <el-table-column label="充电次数" prop="frequency" />
          <el-table-column
            label="充电时长(分钟)"
            prop="sum_cost_time"
            :formatter="rounding"
          />
          <el-table-column label="充电量(度)" prop="sum_quantity" />
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="currentPage"
          :page-size="pageSize"
          :small="small"
          layout="total, slot, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <template #default>
            <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
          </template>
        </el-pagination>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>
  <script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { user_consumption_statistics, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import Data2Excel from "@/component/Data2Excel.vue";
import ProvinceCityCountry from "../../assets/json/address";
export default {
  name: "InvestCollect",
  components:{
    Data2Excel
  },
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        orderTime: ["", ""],
      },
      tableData: [],
      isadmin: false,
      users: [],
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,

      loading: false,
      provinceData: ProvinceCityCountry.address,
      searchParam: {}
    });
    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const rounding = (row, column) => {
      return parseFloat(
        row[column.property] ? row[column.property] : "0"
      ).toFixed(2);
    };
    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.orderTime) {
        stDate = data.FormData.orderTime[0] ? data.FormData.orderTime[0] : "";
        endDate = data.FormData.orderTime[1] ? data.FormData.orderTime[1] : "";
      } else if (
        data.FormData.orderTime === null ||
        data.FormData.orderTime === undefined ||
        data.FormData.orderTime === ""
      ) {
        stDate = "";
        endDate = "";
      }
      data.searchParam = {
        operator_id: sessionStorage.getItem("userID"),
        // username: data.FormData.username,
        phone: data.FormData.phone,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        page:data.currentPage,
        type_id:data.FormData.type_id
      };
      data.loading = true;
      user_consumption_statistics(data.searchParam).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data ;
            data.tableData.forEach((a)=>{
              a.sum_cost_money = Number(a.sum_cost_money )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            })
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList()
    };
    onMounted(() => {
      getUsername();
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getUsername,

      rounding,
      getList,
      // getTime
      handleCurrentChange
    };
  },
};
</script>
  <style scoped>
.bottomDiv {
  height: 100%;
}
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
  width: 100%;
}
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
</style>